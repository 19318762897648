import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import {
  Header,
  FlexGallery,
  FlexGalleryWrapper
} from '../components/layout/index.js';
import { Section } from '../components/common/index.js';
import useTranslations from '../components/layout/useTranslations';
import Layout from '../components/layout';

const GroupsPage = ({ data }) => {
  const {
    groupsTitle,
    groupsBlurb,
    australianHostels,
    nzHostels,
    sunset
  } = useTranslations();
  return (
    <>
      <Helmet
        title="Group accommodation in Australia and New Zealand"
        meta={[
          {
            name: 'description',
            content:
              'Base backpackers are the experts for large group accommodation in AU and NZ with space to cater for large school and sporting groups.'
          }
        ]}
      />
      <Header
        backgroundImage={data.file.childImageSharp.fluid}
        pageTitle={groupsTitle}
        propertyName="Base Magnetic Island"
        caption={sunset}
        tagline={groupsBlurb}
      />

      <Section>
        <h2>{australianHostels}</h2>

        <FlexGalleryWrapper>
          {data.australia.edges.map(({ node: hostel }) => (
            <FlexGallery
              basis="150px"
              basisWide="150px"
              title={hostel.title}
              fluid={hostel.featuredImage.fluid}
              key={hostel.id}
              url={`${hostel.slug}/`}
              alt={hostel.alt}
              id={hostel.id}
            />
          ))}
        </FlexGalleryWrapper>
      </Section>
      {data.nz.edges.length > 1 && (
        <Section>
          <h2>{nzHostels}</h2>

          <FlexGalleryWrapper>
            {data.nz.edges.map(({ node: hostel }) => (
              <FlexGallery
                title={hostel.title}
                fluid={hostel.featuredImage.fluid}
                key={hostel.id}
                url={`${hostel.slug}/`}
                alt={hostel.alt}
                id={hostel.id}
              />
            ))}
          </FlexGalleryWrapper>
        </Section>
      )}
    </>
  );
};

export default GroupsPage;

export const query = graphql`
  query groupsQuery($locale: String!) {
    file(relativePath: { eq: "backpacker-destinations-australia-nz.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    australia: allDatoCmsGroup(
      filter: {
        hostel: { country: { eq: "Australia" }, locale: { eq: $locale } }
      }
    ) {
      edges {
        node {
          id
          title
          slug
          featuredImage {
            alt
            fluid(
              maxWidth: 300
              maxHeight: 200
              imgixParams: {
                fm: "jpg"
                auto: "compress"
                fit: "crop"
                crop: "faces"
              }
            ) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
    nz: allDatoCmsGroup(
      filter: {
        locale: { eq: $locale }
        hostel: { country: { eq: "New Zealand" } }
      }
    ) {
      edges {
        node {
          id
          title
          slug
          featuredImage {
            fluid(
              maxWidth: 300
              maxHeight: 200
              imgixParams: {
                fm: "jpg"
                auto: "compress"
                fit: "crop"
                crop: "faces"
              }
            ) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
  }
`;
